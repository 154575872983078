import React, { useState, useEffect } from 'react';

import { useTranslation } from "react-i18next";

import { Text, View, ActivityIndicator } from 'react-native';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { useTheme, makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import BuyerRankingTop10 from "./buyer_ranking_top10";
import BuyerRankingBottom10 from "./buyer_ranking_bottom10";
import BuyerDisplayTop10 from "./buyer_display_top10";
import HotelDisplayTop10 from "./hotel_display_top10";
import AvgMarkupPercentDaily from "./all_avg_markup_percent_daily";
import AvgMarkupPercentWeekly from "./all_avg_markup_percent_weekly";
import AvgMarkupPercent from "./all_avg_markup_percent";
import AvgSuccessRate from "./all_avg_success_rate";
import TotalProfitDaily from "./all_total_profit_daily";
import VolumeProfitDisplay3D from "./3d_volume_profit_display";
import AvgDisplayCountWeekly from "./all_avg_display_count_weekly";
import AvgDisplayCountDaily from "./all_avg_display_count_daily";
import AvgDefinedMarkupPercentDaily from "./all_avg_defined_markup_percent_daily";
import AvgDefinedMarkupPercentWeekly from "./all_avg_defined_markup_percent_weekly";
import AllTotalProfit from "./all_total_profit";
import AllTotalVolume from "./all_total_volume";

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
  cardSpecial: {
    minWidth: 200,
    backgroundColor: "#d03a39",
    color: "white",
    margin: "1em 1em 0em",
    width: "30% !important"
  },
  title: {
    fontSize: "1rem",
    color: "white"
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: "1em",
    marginRight: "1em",
    width: 200,
  },
});

export default function SuppCards({ hvalue="100%" }) {

  const animatedComponents = makeAnimated();

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);  

  const [supplier, setSuppliers] = useState(null);
  const [supplierSelected, setSupplierSelected] = useState("");
  const [selectLabel, setSelectLabel] = useState("Choose the Supplier *");

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const themeS = useTheme();
  const themeMode = themeS.palette.type;
  const themeBack = themeS.palette.background.paper;

  const classes = useStyles();

  const uid = window.sessionStorage.getItem("uid");

  const supplierSelectedChange = event => {
    setSupplierSelected(event.value);
    setSelectLabel(event.label);
  }

  useEffect(() => {
  
    fetch(process.env.REACT_APP_REQ_IP + '/supplier_list', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}}).then(res => res.json()).then(data => {
      setIsLoading(true);

      if(data.results) {
        setIsLoading(false);

        const suppliers = JSON.parse(data.veri);
        const suppliers_list = []

        suppliers.forEach(val => {
          suppliers_list.push({value: val[0], label: val[1]});
        });

        setSuppliers(suppliers_list)
      }
      else {
        setServermsg(data.message);
      }

    })
    .catch(err => {
      setIsLoading(false);
      setError(err);
    });

  }, [uid]);


  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#d83534" />
        </View>
    );
  }
  // if (serverMsg) {
  //     return (
  //         <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center'}}>
  //             <Text style={{ fontSize: 18, color:"#000"}}>{t("Error")}: {serverMsg}</Text>
  //         </View>
  //     );
  // }
  else {
  return (
    <>
    {supplier !== null &&
      <div>
        <FormControl variant="outlined" className='w-50 my-4' required>
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            value={supplierSelected}
            options={supplier}
            placeholder={selectLabel}
            onChange={supplierSelectedChange}
            menuPosition='fixed'
            maxMenuHeight={400}            
          /> 
        </FormControl>

        <form className={classes.container} noValidate>
          <TextField
            id="sdate"
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="edate"
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>

        {serverMsg &&
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"#000"}}>{t("Error")}: {serverMsg}</Text>
          </View>
        }
      </div>
    }

    {supplierSelected !== "" &&
      <>
      <div className='row'>
          <Card className={classes.cardSpecial} style={{"backgroundColor": "#434344"}}>
            <CardContent className='pb-3'>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Average Markup Percent (%) for Selected Supplier
                </Typography>
                <Typography variant="inherit" component="h4">
                  <AvgMarkupPercent hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
                </Typography>
            </CardContent>
          </Card> 

          <Card className={classes.cardSpecial} style={{"backgroundColor": "#434344"}}>
            <CardContent className='pb-3'>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Average Booked Rate (%) for Selected Supplier
                </Typography>
                <Typography variant="inherit" component="h4">
                  <AvgSuccessRate hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
                </Typography>
            </CardContent>
          </Card> 

          <Card className={classes.cardSpecial} style={{"backgroundColor": "#434344"}}>
            <CardContent className='pb-3'>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Total Profit for Selected Supplier
                </Typography>
                <Typography variant="inherit" component="h4">
                <AllTotalProfit hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
                </Typography>
            </CardContent>
          </Card> 

          <Card className={classes.cardSpecial} style={{"backgroundColor": "#434344"}}>
            <CardContent className='pb-3'>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Total Volume for Selected Supplier
                </Typography>
                <Typography variant="inherit" component="h4">
                <AllTotalVolume hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
                </Typography>
            </CardContent>
          </Card> 
      </div>

      <Typography variant="h3" className="bar-title mt-5">
        {t("Check Availability Count (Daily) for Selected Supplier")}
          <Tooltip title="Number of Displays (Daily) for Selected Supplier" className='info-tooltip'>
            <InfoIcon />
          </Tooltip>
      </Typography>

      <div className="container pages-container">
        <div className={{ flexGrow: 1 }} />
        <AvgDisplayCountDaily hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
      </div> 

      <Typography variant="h3" className="bar-title mt-5">
        {t("Check Availability Count (Weekly) for Selected Supplier")}
          <Tooltip title="Number of Displays (Weekly) for Selected Supplier" className='info-tooltip'>
            <InfoIcon />
          </Tooltip>
      </Typography>

      <div className="container pages-container">
        <div className={{ flexGrow: 1 }} />
        <AvgDisplayCountWeekly hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
      </div> 

      <Typography variant="h3" className="bar-title mt-5">
        {t("Number of Markups Generated (Daily) / Average Markup Percent Generated (Daily) for Selected Supplier")}
            <Tooltip title="Number of different rules (markups) generated by OptiScorer in 'Markup Count' serie. Average Markup Percent generated by OptiScorer in 'Defined Markup Percent' serie." className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <AvgDefinedMarkupPercentDaily hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Number of Markups Generated (Weekly) / Average Markup Percent Generated (Weekly) for Selected Supplier")}
            <Tooltip title="Number of different rules (markups) generated by OptiScorer in 'Markup Count' serie. Average Markup Percent generated by OptiScorer in 'Defined Markup Percent' serie." className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <AvgDefinedMarkupPercentWeekly hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
        </div> 

      <Typography variant="h3" className="bar-title mt-5">
          {t("3D Anomaly Detection for Buyer Couple for Selected Supplier")}
            <Tooltip title="3D Outlier Detection for Buyer Matching for Selected Supplier" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <VolumeProfitDisplay3D hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
          {t("Average Markup Percent (Daily) / Booked Rate (Daily) / Total Profit (Daily) for Selected Supplier")}
            <Tooltip title="Average Displayed Markup Percent (Daily) / Success Rate (Daily) / Total Profit (Daily) for Selected Supplier" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <AvgMarkupPercentDaily hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Average Markup Percent (Weekly) / Booked Rate (Weekly) / Total Profit (Weekly) for Selected Supplier")}
            <Tooltip title="Average Displayed Markup Percent (Weekly) / Success Rate (Weekly) / Total Profit (Weekly) for Selected Supplier" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <AvgMarkupPercentWeekly hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
        </div> 

        
          <Typography variant="h3" className="bar-title mt-5">
          {t("Buyer Booked Rate (Top 10) for Selected Supplier")}
            <Tooltip title="Buyer Rate Ranking (Top 10) for Selected Supplier" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
          </Typography>

          <div className="container pages-container">
            <div className={{ flexGrow: 1 }} />
            <BuyerRankingTop10 hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
          </div> 


          <Typography variant="h3" className="bar-title mt-5">
          {t("Buyer Check Availability Count (Top 10) for Selected Supplier")}
            <Tooltip title="Buyer Display Count (Top 10) for Selected Supplier" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
          </Typography>

          <div className="container pages-container">
            <div className={{ flexGrow: 1 }} />
            <BuyerDisplayTop10 hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
          </div> 

          <Typography variant="h3" className="bar-title mt-5">
          {t("Buyer Booked Rate (Bottom 10) for Selected Supplier")}
            <Tooltip title="Buyer Success Rate (Bottom 10) for Selected Supplier" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
          </Typography>

          <div className="container pages-container">
            <div className={{ flexGrow: 1 }} />
            <BuyerRankingBottom10 hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
          </div> 

          <Typography variant="h3" className="bar-title mt-5">
          {t("Hotel Check Availability Count / Booked Rate (Top 10) for Selected Supplier")}
            <Tooltip title="Hotel Display Count / Success Rate Ranking (Top 10) for Selected Supplier" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
          </Typography>

          <div className="container pages-container">
            <div className={{ flexGrow: 1 }} />
            <HotelDisplayTop10 hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
          </div> 

          <Typography variant="h3" className="bar-title mt-5">
          {t("Total Profit Daily for Selected Supplier")}
            <Tooltip title="Total Profit Daily for Selected Supplier" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
          </Typography>

          <div className="container pages-container">
            <div className={{ flexGrow: 1 }} />
            <TotalProfitDaily hvalue="250%" supplier={supplierSelected} start={startDate} end={endDate}/>
          </div> 

        </>
      } 

    </>

    );
  }
}