
export function addThousandSeperator (number, decimalp=2) {
    // Use toLocaleString() with options to add thousand separators and keep decimal point
    return number.toLocaleString('en-US', {
      minimumFractionDigits: Number(decimalp),
      maximumFractionDigits: Number(decimalp),
    });
};


