import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

import { useTheme } from '@material-ui/core/styles';

import { addThousandSeperator } from "../components/ThousandSep";

export default function AvgMarkupPercentDaily({ hvalue="100%", supplier="", buyer="", start="", end="" }) {

    const [lineGrap1, setLineGrap1] = useState(null);
    const [lineGrap2, setLineGrap2] = useState(null);
    const [lineGrap3, setLineGrap3] = useState(null);

    const [serverMsg, setServermsg] = useState(null);
    const { t, i18n } = useTranslation();

    const themeS = useTheme();
    const themeMode = themeS.palette.type;
    const themeBack = themeS.palette.background.paper;

    const uid = window.sessionStorage.getItem("uid");

    useEffect(() => {

      var list_obj = {
        "buyer": buyer,
        "supplier": supplier,
        "start": start,
        "end": end,
      }

        fetch(process.env.REACT_APP_REQ_IP + '/average_markup_percent_daily/<start>/<end>/<buyer>/<supplier>', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
          if(data.results) {
            const line_plot1 = [];

            const nb1 = JSON.parse(data.veri);

            nb1.map((keys, value) => {

              line_plot1.push({
                  'x': keys.date,
                  'y': keys.markup_percent.toFixed(2)
              });

            });
            
            setLineGrap1(line_plot1);
        
        }
        else {
            setServermsg(data.message);
        }

        })
        .catch(err => {
        // setIsLoading(false);
        // setError(err);
            console.log(err)
        });


        fetch(process.env.REACT_APP_REQ_IP + '/average_success_rate_daily/<start>/<end>/<buyer>/<supplier>', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
          if(data.results) {
            const line_plot2 = [];

            const nb1 = JSON.parse(data.veri);

            nb1.map((keys, value) => {

              line_plot2.push({
                  'x': keys.date,
                  'y': (keys.success_rate * 100).toFixed(4)
              });

            });

            setLineGrap2(line_plot2);
        
        }
        else {
            setServermsg(data.message);
        }

        })
        .catch(err => {
            console.log(err)
        });


        fetch(process.env.REACT_APP_REQ_IP + '/total_profit_daily/<start>/<end>/<buyer>/<supplier>', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
          
          if(data.results) {
            const line_plot3 = [];

            const nb1 = JSON.parse(data.veri);

            nb1.map((keys, value) => {

              line_plot3.push({
                  'x': keys.date,
                  'y': keys.profit.toFixed(2)
              });

            });

            setLineGrap3(line_plot3);
        
        }
        else {
            setServermsg(data.message);
        }

        })
        .catch(err => {
            console.log(err)
        });

    }, [uid, buyer, supplier, start, end]);


  return (
    <>

    {(lineGrap1 === null || lineGrap2 === null || lineGrap3 === null) && 
      <div className="blur-container"> 
            <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"1em", borderColor:"#424243", borderWidth:"1px" }} resizeMethod='scale' />
        <span>Data is invalid</span>
        {serverMsg !== null &&
            <span>{t(serverMsg)}</span>
        }
      </div>
    }

    {lineGrap1 !== null && lineGrap2 !== null && lineGrap3 !== null && serverMsg === null &&
      
      <>
      <Chart
          type="line"
          height={hvalue}
          options={
            {
              chart: {
                animations: {
                  enabled: false
                },
                stacked: false,
                zoom: {
                  autoScaleYaxis: true,
                  autoScaleXaxis: true
                },
                parentHeightOffset: 0,
                background: themeBack
              },
              grid: {
                row: {
                  colors: ['#fff', '#f2f2f2']
                }
              },
              theme: {
                mode: themeMode, 
              },          
              colors: ['#008ffb', '#00e396', '#891eff'],
              fill: {
                opacity: [1]
              },
              stroke: {
                curve: 'straight',
                width: [4, 4, 4]
              },
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: '12px',
                  fontWeight: 'bold',
                },
                background: {
                  enabled: true,
                  foreColor: '#fff',
                  borderRadius: 2,
                  padding: 4,
                  opacity: 0.9,
                  borderWidth: 1,
                  borderColor: '#fff'
                },
                formatter: function (val, opts) {
                  return addThousandSeperator(val)
                },
              },
              tooltip: {
                y: [
                {
                  formatter: function(value) {
                    return addThousandSeperator(value) + '%'
                  }
                },
                {
                  formatter: function(value) {
                    return addThousandSeperator(value, 4)
                  }
                },
                {
                  formatter: function(value) {
                    return addThousandSeperator(value)
                  }
                }
              ]
              },
              xaxis: {
                type: 'datetime',
                labels: {
                  formatter: function(value, timestamp, index) {
                    return Intl.DateTimeFormat('ja-JP', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(value)
                  },
                },
                title: {
                  text: t("Daily Dates"),
                }
              },    
              yaxis: 
                [
                  {
                    seriesName: 'Markup Percent(%)',
                    axisTicks: {
                      show: true,
                      color: '#008ffb'
                    },
                    axisBorder: {
                      show: true,
                      width: 2,
                      color: '#008ffb'
                    },
                    labels: {
                      formatter: function(value) {
                        if(value !== null){
                          return value.toFixed(2);
                        }
                      },
                      style: {
                        colors: '#008ffb',
                      }
                    },
                    title: {
                      text: t("Markup Percent(%)"),
                      style: {
                        color: '#008ffb',
                      }
                    }
                  },

                  {
                    seriesName: 'Booked Rate',
                    opposite: true,
                    axisTicks: {
                      show: true,
                      color: '#00e396'
                    },
                    axisBorder: {
                      show: true,
                      width: 2,
                      color: '#00e396'
                    },
                    labels: {
                      formatter: function(value) {
                        if(value !== null){
                          return (value * 100).toFixed(4);
                        }
                      },
                      style: {
                        colors: '#00e396',
                      }
                    },
                    title: {
                      text: "Booked Rate",
                      style: {
                        color: '#00e396',
                      }
                    },
                  },

                  {
                    seriesName: 'Total Profit',
                    opposite: true,
                    axisTicks: {
                      show: true,
                      color: '#891eff'
                    },
                    axisBorder: {
                      show: true,
                      width: 2,
                      color: '#891eff'
                    },
                    labels: {
                      formatter: function(value) {
                        if(value !== null){
                          return value.toFixed(2);
                        }
                      },
                      style: {
                        colors: '#891eff',
                      }
                    },
                    title: {
                      text: "Total Profit",
                      style: {
                        color: '#891eff',
                      }
                    },
                  },

                ]
            }
          }
          series={[
            {
              type: "line",
              data: lineGrap1,
              name : "Markup Percent(%)"
            },
            {
              type: "area",
              data: lineGrap2,
              name : "Booked Rate"
            },
            {
              type: "line",
              data: lineGrap3,
              name : "Total Profit"
            }
          
          ]}
        />
      </>
    }

    </>

    );
}