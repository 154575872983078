import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';

import { useNavigate } from "react-router-dom";

import LangSwitch from '../components/LangSwitch';
import { useTranslation } from "react-i18next";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import * as Components from "../assets/js/auth";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const bcrypt = require('react-native-bcrypt');

function Sign() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [signIn, toggle] = useState(true);

    const { t, i18n } = useTranslation();

    let uid = window.sessionStorage.getItem("uid");

    //if user authenticated
    if(uid !== null) {
      navigate("/app", { replace: true });
    }
  
    function handleUserLogin() { 
        setIsLoading(true);

        const email = document.getElementById('user_lemail').value;
        const pass = document.getElementById('user_lpassword').value;

        const list_obj = {
            "email": email,
        }
        
        fetch(process.env.REACT_APP_REQ_IP + '/users', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            setIsLoading(false);
            
            if(data.result === false) {
              enqueueSnackbar(t(data.message), { 
                variant: 'error',
                persist: false
              });
            }
            else {
                var tempCompare = bcrypt.compareSync(pass, data.userInfo[0][6]); 

                if(tempCompare === true) {

                    window.sessionStorage.setItem("uid", data.userInfo[0][0]);
                    window.sessionStorage.setItem("comp_id", data.userInfo[0][1]);
                    window.sessionStorage.setItem("email", data.userInfo[0][2]);
                    window.sessionStorage.setItem("fname", data.userInfo[0][4]);
                    window.sessionStorage.setItem("lname", data.userInfo[0][5]);

                    window.sessionStorage.setItem("tax_no", data.userInfo[0][9]);
                    window.sessionStorage.setItem("tax_address", data.userInfo[0][10]);
                    window.sessionStorage.setItem("company", data.userInfo[0][11]);
                    window.sessionStorage.setItem("address", data.userInfo[0][12]);

                    navigate("/app", { replace: true });
                }
                else {
                    enqueueSnackbar(t("Your password is incorrect, please try again."), { 
                      variant: 'error',
                      persist: false
                    });
                }
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#d83534" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (       
          <div className='login-body'>  
            <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
            <Components.Container>

              <Components.SignInContainer signingIn={signIn}>
                <LangSwitch />
                <form className='login-form' method="POST" onSubmit={handleUserLogin}>
                  <div className='login-sign-title'>

                    <Components.Anchor className='' href="https://globaltradeai.com/">
                      <img src="logo.png" alt="logo" className="logo-header" />
                    </Components.Anchor>

                  </div>
                  <Components.Input type="email" placeholder={t("Email")} className="form-control " id="user_lemail" required />
                  <Components.Input type="password" placeholder={t("Password")} className="form-control " id="user_lpassword" required/>
                  <Components.Ghost2Button className='align-self-center' type="submit">{t("Sign In")}</Components.Ghost2Button>
                </form>
              </Components.SignInContainer>

              <Components.OverlayContainer signingIn={signIn}>
                <Components.Overlay signingIn={signIn}>
                  <Components.RightOverlayPanel signingIn={signIn}>
                    <Components.Title>{t("Do you want to grow your business with AI")}?</Components.Title>
                    <Components.Paragraph className='d-flex align-items-center'>
                    <ArrowBackIosIcon /> { t("Discover more") } <ArrowForwardIosIcon />
                    </Components.Paragraph>
                  </Components.RightOverlayPanel>
                </Components.Overlay>
              </Components.OverlayContainer>

            </Components.Container>
          </div>
        );
    }
  }

export default Sign;