import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';

import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HelpIcon from '@material-ui/icons/Help';
import BusinessIcon from '@material-ui/icons/Business';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HomeIcon from '@material-ui/icons/Home';

import { useTranslation } from "react-i18next";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }
}));

export default function ({ open, handleDrawerClose, handleSidebar }) {

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Drawer className={classes.drawer} variant="persistent" anchor="left" open={open} classes={{paper: classes.drawerPaper}}>

      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      
      <Divider />

      <div>
        <List>

          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="customDash" onClick={handleSidebar} disableRipple>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={t("Custom Board")}/>
            </Button>
          </ListItem>

          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="homeDash" onClick={handleSidebar} disableRipple>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={t("Over All")}/>
            </Button>
          </ListItem>
          
          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="buyerPred" onClick={handleSidebar} disableRipple>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={t("Buyer Analytics")}/>
            </Button>
          </ListItem>

          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="supplierPred" onClick={handleSidebar} disableRipple>
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary={t("Supplier Analytics")}/>
            </Button>
          </ListItem>

          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="offerPred" onClick={handleSidebar} disableRipple>
              <ListItemIcon>
                <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary={t("Buyer/Supplier Analytics")}/>
            </Button>
          </ListItem>
          
        </List>

        <Divider />

        <List>
            <ListItem button>
              <Button className="d-flex align-items-center side-button" value="help" href="https://optiwisdom.com/tr/iletisim/" disableRipple>
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary={t("Help")} />
              </Button>
            </ListItem>
        </List>

      </div>
    </Drawer>
  );
}
