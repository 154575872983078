import React, { useState, useEffect } from 'react';

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';

import { Text, View, ActivityIndicator } from 'react-native';

import { addThousandSeperator } from "../components/ThousandSep";

export default function AvgSuccessMarkupPercent({ hvalue="100%", start="", end="" }) {

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);  

  const [nb, setNB] = useState(null);

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const uid = window.sessionStorage.getItem("uid");

  useEffect(() => {

    var list_obj = {
        "buyer": "",
        "supplier": "",
        "start": start,
        "end": end,
      }
  
    fetch(process.env.REACT_APP_REQ_IP + '/average_success_markup_percent/<start>/<end>/<buyer>/<supplier>', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {        
        if(data.results) {
            if(data.veri[0] !== undefined) {
                const nb1 = JSON.parse(data.veri[0]);
                setNB(addThousandSeperator(Number(nb1)));
            }
            else {
                setNB(null);  
            }
        }
        else {
          setServermsg(data.message);
        }
  
      })
      .catch(err => {
        setIsLoading(false);
        setError(err);
      });
  
  }, [uid, start, end]);


  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#d83534" />
        </View>
    );
  }
  if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
          </View>
      );
  }
  else {
  return (
    <>
    {(nb === null || nb === undefined) && 
        <div className="blur-container"> 
            <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"1em", borderColor:"#424243", borderWidth:"1px" }} resizeMethod='scale' />
            <span>Data is invalid</span>
            {serverMsg !== null &&
                <span>{t(serverMsg)}</span>
            }
        </div>
    }
    {nb !== null && nb !== undefined && serverMsg === null &&
        <div className='d-flex justify-content-start h-100 align-items-center'>
            <CallMissedOutgoingIcon className='bar-m-icon-12'/>
            <div className='bar-value-12'>{nb} %</div>
        </div>
    }

    </>

    );
  }
}