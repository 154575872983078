import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import AvgSuccessRate from "./all_avg_success_rate";
import AvgMarkupPercentDaily from "./all_avg_markup_percent_daily";
import AvgMarkupPercentWeekly from "./all_avg_markup_percent_weekly";
import AvgMarkupPercent from "./all_avg_markup_percent";
import TotalProfitDaily from "./all_total_profit_daily";
import AvgDisplayCountWeekly from "./all_avg_display_count_weekly";
import AvgDisplayCountDaily from "./all_avg_display_count_daily";
import HotelDisplayTop10 from "./hotel_display_top10";
import AvgDefinedMarkupPercentDaily from "./all_avg_defined_markup_percent_daily";
import AvgDefinedMarkupPercentWeekly from "./all_avg_defined_markup_percent_weekly";
import VolumeProfitDisplay3D from "./3d_volume_profit_display";
import SupplierRankingTop10 from "./supplier_ranking_top10";
import BuyerRankingTop10 from "./buyer_ranking_top10";
import SupplierRankingBottom10 from "./supplier_ranking_bottom10";
import BuyerRankingBottom10 from "./buyer_ranking_bottom10";
import AvgSuccessMarkupPercent from "./all_avg_success_markup_percent";
import SupplierDisplayTop10 from "./supplier_display_top10";
import BuyerDisplayTop10 from "./buyer_display_top10";
import AllTotalProfit from "./all_total_profit";
import AllTotalVolume from "./all_total_volume";

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  cardSpecial: {
    minWidth: 200,
    width: "22%",
    backgroundColor: "#d03a39",
    color: "white",
    margin: "1em 1em 0em",
    width: "30% !important"
  },
  title: {
    fontSize: "1rem",
    color: "white"
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: "1em",
    marginRight: "1em",
    width: 200,
  },
});

export default function OverAll({}) {

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { t, i18n } = useTranslation();

  const classes = useStyles();

  return (
    <>
      <div className='w-50 my-4'>
        <form className={classes.container} noValidate>
          <TextField
            id="sdate"
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="edate"
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>

      </div>


      <>

      <div className='row'>
          <Card className={classes.cardSpecial} style={{"backgroundColor": "#434344"}}>
            <CardContent className='pb-3'>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Overall Average Markup Percent (%) for Selected Date Range
                </Typography>
                <Typography variant="inherit" component="h4">
                  <AvgMarkupPercent hvalue="250%" start={startDate} end={endDate}/>
                </Typography>
            </CardContent>
          </Card> 

          <Card className={classes.cardSpecial} style={{"backgroundColor": "#434344"}}>
            <CardContent className='pb-3'>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Average Booked Rate (%) for Selected Date Range
                </Typography>
                <Typography variant="inherit" component="h4">
                  <AvgSuccessRate hvalue="250%" start={startDate} end={endDate}/>
                </Typography>
            </CardContent>
          </Card> 

          <Card className={classes.cardSpecial} style={{"backgroundColor": "#434344"}}>
            <CardContent className='pb-3'>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Average Markup Rate for Booked for Selected Date Range
                </Typography>
                <Typography variant="inherit" component="h4">
                <AvgSuccessMarkupPercent hvalue="250%" start={startDate} end={endDate}/>
                </Typography>
            </CardContent>
          </Card> 

          <Card className={classes.cardSpecial} style={{"backgroundColor": "#434344"}}>
            <CardContent className='pb-3'>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Total Profit for Selected Date Range
                </Typography>
                <Typography variant="inherit" component="h4">
                <AllTotalProfit hvalue="250%" start={startDate} end={endDate}/>
                </Typography>
            </CardContent>
          </Card> 

          <Card className={classes.cardSpecial} style={{"backgroundColor": "#434344"}}>
            <CardContent className='pb-3'>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                Total Volume for Selected Date Range
                </Typography>
                <Typography variant="inherit" component="h4">
                <AllTotalVolume hvalue="250%" start={startDate} end={endDate}/>
                </Typography>
            </CardContent>
          </Card> 
      </div>

      <Typography variant="h3" className="bar-title mt-5">
        {t("Number of Markups Generated (Daily) / Average Markup Percent Generated (Daily) for Selected Date Range")}
            <Tooltip title="Number of different rules (markups) generated by OptiScorer in 'Markup Count' serie. Average Markup Percent generated by OptiScorer in 'Defined Markup Percent' serie." className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <AvgDefinedMarkupPercentDaily hvalue="250%" start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Number of Markups Generated (Weekly) / Average Markup Percent Generated (Weekly) for Selected Date Range")}
            <Tooltip title="Number of different rules (markups) generated by OptiScorer in 'Markup Count' serie. Average Markup Percent generated by OptiScorer in 'Defined Markup Percent' serie." className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <AvgDefinedMarkupPercentWeekly hvalue="250%" start={startDate} end={endDate}/>
        </div> 


        <Typography variant="h3" className="bar-title mt-5">
        {t("Buyer Booked Rate (Bottom 10) for Selected Date Range")}
            <Tooltip title="Buyer Success Rate Ranking (Bottom 10) for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <BuyerRankingBottom10 hvalue="250%" start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Supplier Booked Rate (Bottom 10) for Selected Date Range")}
            <Tooltip title="Supplier Success Rate Ranking (Bottom 10) for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <SupplierRankingBottom10 hvalue="250%" start={startDate} end={endDate}/>
        </div> 


        <Typography variant="h3" className="bar-title mt-5">
        {t("Buyer Booked Rate (Top 10) for Selected Date Range")}
            <Tooltip title="Buyer Success Rate Ranking (Top 10) for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <BuyerRankingTop10 hvalue="250%" start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Supplier Booked Rate (Top 10) for Selected Date Range")}
            <Tooltip title="Supplier Success Rate Ranking (Top 10) for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <SupplierRankingTop10 hvalue="250%" start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Hotel Check Availability Count / Booked Rate (Top 10) for Selected Date Range")}
            <Tooltip title="Hotel Display Count / Success Rate Ranking (Top 10) for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <HotelDisplayTop10 hvalue="250%" start={startDate} end={endDate}/>
        </div> 


        <Typography variant="h3" className="bar-title mt-5">
        {t("Check Availability Count (Daily) for Selected Date Range")}
            <Tooltip title="Number of Displays (Daily) for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <AvgDisplayCountDaily hvalue="250%" start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Check Availability Count (Weekly) for Selected Date Range")}
            <Tooltip title="Number of Displays (Weekly) for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <AvgDisplayCountWeekly hvalue="250%" start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Supplier Check Availability Count (Top 10) for Selected Date Range")}
            <Tooltip title="Supplier Display Count (Top 10) for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <SupplierDisplayTop10 hvalue="250%" start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Buyer Check Availability Count (Top 10) for Selected Date Range")}
            <Tooltip title="Buyer Display Count (Top 10) for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <BuyerDisplayTop10 hvalue="250%" start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
          {t("3D Anomaly Detection for Buyer and Supplier Couples for Selected Date Range")}
            <Tooltip title="3D Outlier Detection for Buyer and Supplier Matching for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <VolumeProfitDisplay3D hvalue="250%" start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Average Markup Percent (Daily) / Booked Rate (Daily) / Total Profit (Daily) for Selected Date Range")}
            <Tooltip title="Average Displayed Markup Percent (Daily) / Success Rate (Daily) / Total Profit (Daily) for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <AvgMarkupPercentDaily hvalue="250%" start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Average Markup Percent (Weekly) / Booked Rate (Weekly) / Total Profit (Weekly) for Selected Date Range")}
            <Tooltip title="Average Displayed Markup Percent (Weekly) / Success Rate (Weekly) / Total Profit (Weekly) for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <AvgMarkupPercentWeekly hvalue="250%" start={startDate} end={endDate}/>
        </div> 

        <Typography variant="h3" className="bar-title mt-5">
        {t("Total Profit Daily for Selected Date Range")}
            <Tooltip title="Total Profit Daily for Selected Date Range" className='info-tooltip'>
              <InfoIcon />
            </Tooltip>
        </Typography>

        <div className="container pages-container">
          <div className={{ flexGrow: 1 }} />
          <TotalProfitDaily hvalue="250%" start={startDate} end={endDate}/>
        </div> 

      </>

    </>

    );
  
}