import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  popup: {
    padding: theme.spacing(2)
  }
}));

const widgetNames = {
  "1": 'Average Booked Rate (%)',
  "2": 'Overall Average Markup Percent (%)',
  "3": "Average Markup Percent (Weekly) / Booked Rate (Weekly) / Total Profit (Weekly)",
  "4": "Average Markup Percent (Daily) / Booked Rate (Daily) / Total Profit (Daily)",
  // "5": 'Average Success Rate (Weekly)',
  // "6": 'Average Success Rate (Daily)',
  "7": 'Check Availability Count (Weekly)',
  "8": 'Check Availability Count (Daily)',
  "9": "Hotel Check Availability Count / Booked Rate (Top 10)",
  // "10": "Hotel Ranking (Top 10)",
  "11": 'Supplier Booked Rate (Top 10)',
  "12": "Buyer Booked Rate (Top 10)",
  "13": "Supplier Booked Rate (Bottom 10)",
  "14": "Buyer Booked Rate (Bottom 10)",
  "15": "Average Markup Rate for Booked",
  "16": 'Supplier Check Availability Count (Top 10)',
  "17": 'Buyer Check Availability Count (Top 10)',
  "18": "Total Profit Daily",
  // "19": "Total Volume Daily",
};

export default function AddList({ items, onRemoveItem, onAddItem, originalItems }) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const { t, i18n } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (e) => {
    if (e.target.checked) {
      onAddItem(e.target.name);
    } else {
      onRemoveItem(e.target.name);
    }
  };

  return (
    <>
      <IconButton aria-label="add" onClick={handleClick} aria-describedby={id}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}>
        <div className={classes.popup}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("Select analysis")}</FormLabel>
            <FormGroup>
              {originalItems.map((i) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={items.includes(i)}
                      onChange={handleChange}
                      name={i}
                    />
                  }
                  label={t(widgetNames[i])}
                  key={i}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      </Popover>
    </>
  );
}
