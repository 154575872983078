export function handleLogout() { 
    window.sessionStorage.removeItem('uid');
    window.sessionStorage.removeItem('comp_id');
    window.sessionStorage.removeItem('email');

    window.sessionStorage.removeItem('fname');
    window.sessionStorage.removeItem('lname');

    window.sessionStorage.removeItem('tax_no');
    window.sessionStorage.removeItem('tax_address');

    window.sessionStorage.removeItem('company');
    window.sessionStorage.removeItem('address');
}