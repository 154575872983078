import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

import { useTheme } from '@material-ui/core/styles';

import { addThousandSeperator } from "../components/ThousandSep";

export default function AvgDisplayCountDaily({ hvalue="100%", buyer="", supplier="", start="", end="" }) {

    const [lineGrap1, setLineGrap1] = useState(null);

    const [serverMsg, setServermsg] = useState(null);
    const { t, i18n } = useTranslation();

    const themeS = useTheme();
    const themeMode = themeS.palette.type;
    const themeBack = themeS.palette.background.paper;

    const uid = window.sessionStorage.getItem("uid");

    useEffect(() => {

      var list_obj = {
        "buyer": buyer,
        "supplier": supplier,
        "start": start,
        "end": end,
      }

        fetch(process.env.REACT_APP_REQ_IP + '/average_display_count_daily/<start>/<end>/<buyer>/<supplier>', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
        if(data.results) {
            const line_plot1 = [];

            const nb1 = JSON.parse(data.veri);

            nb1.map((keys, value) => {

            line_plot1.push({
                'x': keys.date,
                'y': keys.display_count.toFixed(2)
            });

            });

            setLineGrap1(line_plot1);
        
        }
        else {
            setServermsg(data.message);
        }

        })
        .catch(err => {
        // setIsLoading(false);
        // setError(err);
            console.log(err)
        });

    }, [uid, buyer, supplier, start, end]);

  return (
    <>

    {lineGrap1 === null && 
      <div className="blur-container"> 
            <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"1em", borderColor:"#424243", borderWidth:"1px" }} resizeMethod='scale' />
        <span>Data is invalid</span>
            {serverMsg !== null &&
                <span>{t(serverMsg)}</span>
            }
      </div>
    }

    {lineGrap1 !== null && serverMsg === null &&
      
      <>
      <Chart
          type="line"
          height={hvalue}
          options={
            {
              chart: {
                animations: {
                  enabled: false
                },
                stacked: false,
                zoom: {
                  autoScaleYaxis: true,
                  autoScaleXaxis: true
                },
                parentHeightOffset: 0,
                background: themeBack
              },
              grid: {
                row: {
                  colors: ['#fff', '#f2f2f2']
                }
              },
              theme: {
                mode: themeMode, 
              },          
              colors: ['#33cd32'],
              fill: {
                opacity: [1]
              },
              stroke: {
                curve: 'straight',
                width: [4]
              },
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: '12px',
                  fontWeight: 'bold',
                },
                background: {
                  enabled: true,
                  foreColor: '#fff',
                  borderRadius: 2,
                  padding: 4,
                  opacity: 0.9,
                  borderWidth: 1,
                  borderColor: '#fff'
                },
                formatter: function (val, opts) {
                  return addThousandSeperator(val)
                },
              },
              tooltip: {
                y: {
                  formatter: function(value) {
                    return addThousandSeperator(value)
                  }
                }
              },
              xaxis: {
                type: 'datetime',
                labels: {
                  formatter: function(value, timestamp, index) {
                    return Intl.DateTimeFormat('ja-JP', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(value)
                  },
                },
                title: {
                  text: t("Daily Dates"),
                }
              },    
              yaxis: {
                title: {
                  text: t("Check Availability Count (#)"),
                }
              },
            }
          }
          series={[{
            data: lineGrap1,
            name : "Check Availability Count (#)"
          }]}
        />
      </>
    }

    </>

    );
}