import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from "@material-ui/core/styles";

import MenuIcon from "@material-ui/icons/Menu";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { handleLogout } from '../components/Logout.js';

import LangSwitch from '../components/LangSwitch';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      zIndex: theme.zIndex.drawer + 1
    }
  },
  rightIcons: {
    marginLeft: theme.spacing(0.5)
  },
  spacer: {
    flexGrow: 1
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function Header({ handleDrawerToggle, toggleDarkMode, darkMode, handleSidebar}) {

  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  //routing components
  let navigate = useNavigate();

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const open = Boolean(anchorEl);

  //if user not authenticated
  if(window.sessionStorage.getItem("uid") === null) {
    handleLogout();
    navigate("/login", { replace: true });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutBtn = () => { 
    handleLogout();
    navigate("/login", { replace: true });
  }

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <AppBar position="fixed" className={classes.appbar}>
      <Toolbar>
        <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start">
          <MenuIcon />
        </IconButton>

        <img src="logo.png" alt="logo" className="logo-header" />

        <div className={classes.spacer} />

        <LangSwitch />

        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDarkMode}
          edge="start"
          className={classes.rightIcons}>
          {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.rightIcons}
          onClick={handleClick}
          
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <AccountCircleIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}      
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}>

          <MenuItem onClick={handleSidebar} value="profile">
            <ListItemIcon>
            <PersonOutlineIcon />
            </ListItemIcon>
            {t("Profile Settings")}
          </MenuItem>

          <Divider />

          <MenuItem onClick={handleLogoutBtn}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            {t("Logout")}
          </MenuItem>
      </Menu>

      </Toolbar>

    </AppBar>
  );
}