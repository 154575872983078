import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

import { Text, View, ActivityIndicator } from 'react-native';

import { useTheme } from '@material-ui/core/styles';

import { addThousandSeperator } from "../components/ThousandSep";

export default function SupplierRankingTop10({ hvalue="100%", buyer="", start="", end="" }) {

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);  

  const [chartData, setChartData] = useState(null);

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const themeS = useTheme();
  const themeMode = themeS.palette.type;
  const themeBack = themeS.palette.background.paper;

  const uid = window.sessionStorage.getItem("uid");

  useEffect(() => {

    var list_obj = {
      "buyer": buyer,
      "supplier": "",
      "start": start,
      "end": end,
    }
  
    fetch(process.env.REACT_APP_REQ_IP + '/supplier_rankings_top10/<start>/<end>/<buyer>/<supplier>', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {        
        if(data.results) {
          const values = JSON.parse(data['veri']);
          values.map(val => {
            val.y = val.y * 100;
          })
          setChartData(values);
        }
        else {
          setServermsg(data.message);
        }
  
      })
      .catch(err => {
        setIsLoading(false);
        setError(err);
      });
  
  }, [uid, buyer, start, end]);


  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#d83534" />
        </View>
    );
  }
  if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
          </View>
      );
  }
  else {
  return (
    <>
    {chartData === null &&
        <div className="blur-container"> 
            <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"1em", borderColor:"#424243", borderWidth:"1px" }} resizeMethod='scale' />
            <span>Data is invalid</span>
            {serverMsg !== null &&
                <span>{t(serverMsg)}</span>
            }
        </div>
    }

    {chartData !== null && serverMsg === null &&
      <Chart
      type="bar"
      height={hvalue}
      options={
      {
      chart: {
        animations: {
          enabled: false
        },
        stacked: false,
        parentHeightOffset: 0,
        background: themeBack
      },
      theme: {
        mode: themeMode, 
      },   
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          // columnWidth: '50%',
        }
      },
      stroke: {
        width: 2
      },
      grid: {
        row: {
          colors: ['#fff', '#f2f2f2']
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100]
        },
      },
      xaxis: {
        // type: "category",
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false,
          trim: true
        },
        tickPlacement: "on",
        title: {
          text: t("Supplier ID/Name"),
        }
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            if(value !== null){
              return value.toFixed(2);
            }
          }
        },
        title: {
          text: t("Booked Rate (%)"),
        }
      },
      tooltip: {
        theme: themeMode,
        marker: {
          show: false
        },
        y: {
          formatter: function(value) {
            return addThousandSeperator(value)
          }
        }
      },
      }
    }
    series={[{data : chartData, name : t("Booked Rate (%)")}]}
  />
    }

    </>

    );
  }
}