import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem"
  },
  spacer: {
    flexGrow: 1
  }
});

const widgetNames = {
  "1": 'Average Booked Rate (%)',
  "2": 'Overall Average Markup Percent (%)',
  "3": "Average Markup Percent (Weekly) / Booked Rate (Weekly) / Total Profit (Weekly)",
  "4": "Average Markup Percent (Daily) / Booked Rate (Daily) / Total Profit (Daily)",
  // "5": 'Average Success Rate (Weekly)',
  // "6": 'Average Success Rate (Daily)',
  "7": 'Check Availability Count (Weekly)',
  "8": 'Check Availability Count (Daily)',
  "9": "Hotel Check Availability Count / Booked Rate (Top 10)",
  // "10": "Hotel Ranking (Top 10)",
  "11": 'Supplier Booked Rate (Top 10)',
  "12": "Buyer Booked Rate (Top 10)",
  "13": "Supplier Booked Rate (Bottom 10)",
  "14": "Buyer Booked Rate (Bottom 10)",
  "15": "Average Markup Rate for Booked",
  "16": 'Supplier Check Availability Count (Top 10)',
  "17": 'Buyer Check Availability Count (Top 10)',
  "18": "Total Profit Daily",
  // "19": "Total Volume Daily",
};

const tips = {
  "1": "Average Booked Rate (%)",
  "2": "Overall Average Markup Percent (%)",
  "3": "Average Displayed Markup Percent (Weekly) / Success Rate (Weekly) / Total Profit (Weekly)",
  "4": "Average Displayed Markup Percent (Daily) / Success Rate (Daily) / Total Profit (Daily)",
  // "5": "Average success rate weekly (booked percent) for all buyer / supplier / hotel combinations.",
  // "6": "Average success rate daily (booked percent) for all buyer / supplier / hotel combinations.",
  "7": "Number of Displays (Weekly)",
  "8": "Number of Displays (Daily)",
  "9": "Hotel Display Count / Success Rate Ranking (Top 10)",
  // "10": "Top 10 Hotel Ranking by the success rate (booked percent)",
  "11": "Supplier Rate Ranking (Top 10)",
  "12": "Buyer Rate Ranking (Top 10)",
  "13": "Supplier Rate Ranking (Bottom 10)",
  "14": "Buyer Success Rate (Bottom 10)",
  "15": "Average Markup Rate for Booked",
  "16": "Supplier Display Count (Top 10)",
  "17": "Buyer Display Count (Top 10)",
  "18": "Total Profit Daily",
  // "19": "Total Volume Daily"
};

var noInteracts = document.getElementsByClassName('userInteract');

[].map.call(noInteracts, function(elem) {
    elem.addEventListener("keydown", function(e) {
        if (e.keyCode !== 9) {
            e.returnValue = false;
            return false;
        }
    }, true);
});

export default function Widget({ id, onRemoveItem, userInteractType, component: Item }) {

  const [interactClass, setInteractClass] = useState("userInteractBody userInteract");

  const { t, i18n } = useTranslation();

  const classes = useStyles();

  useEffect(() => {

    if(userInteractType === true) {
      setInteractClass("userInteractBody userInteract");
    }
    else {
      setInteractClass("userInteractBody");
    }

  }, [userInteractType]);

  return (
    <Card className="root-container">
      <div className={classes.header}>
        
        <Typography variant="caption" className="bar-title-2" gutterBottom>
          {t(widgetNames[id])}
        </Typography>

        <Tooltip title={tips[id]}>
          <InfoIcon />
        </Tooltip>
        <div className={classes.spacer} />
        <IconButton aria-label="delete" onClick={() => onRemoveItem(id)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <div className={interactClass}>
        <Item />
      </div>
    </Card>
  );
}