import ReactDOM from "react-dom";

import './i18n';
import App from "./App";

import './index.css';

// const rootElement = document.getElementById("root");
// ReactDOM.render(
//   // <StrictMode>
//     <App />
//   // </StrictMode>,
//   rootElement
// );


ReactDOM.render(
  <App />,
document.getElementById('root')
);