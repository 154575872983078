import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Sign from './pages/Sign';
import Missing from './pages/Missing';

import Theme from './Theme';

class App extends React.Component {	
    constructor(props){
      super(props);
    
      this.state = {};
    }
    
    render(){
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Sign />}/> 
            <Route path="/login" element={<Sign />}/> 
            <Route path='/app' element = {<Theme/>}/>
            <Route path="*" element={<Missing />} />
          </Routes>
        </BrowserRouter>   
      )
    }
}

render(<App></App>, document.querySelector('#root'));

export default App;

