import React, { useState, useEffect} from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";

import TopBar from "./components/TopBar";
import Widget from "./components/Widget";

import AvgSuccessRate from "./charts/all_avg_success_rate";
import AvgMarkupPercent from "./charts/all_avg_markup_percent";
import AvgMarkupPercentWeekly from "./charts/all_avg_markup_percent_weekly";
import AvgMarkupPercentDaily from "./charts/all_avg_markup_percent_daily";
// import AvgSucessRateWeekly from "./charts/all_avg_success_rate_weekly";
// import AvgSucessRateDaily from "./charts/all_avg_sucess_rate_daily";
import AvgDisplayCountWeekly from "./charts/all_avg_display_count_weekly";
import AvgDisplayCountDaily from "./charts/all_avg_display_count_daily";
import HotelDisplayTop10 from "./charts/hotel_display_top10";
// import HotelSuccessRateTop10 from "./charts/hotel_success_rate_top10";
import SupplierRankingTop10 from "./charts/supplier_ranking_top10";
import BuyerRankingTop10 from "./charts/buyer_ranking_top10";
import SupplierRankingBottom10 from "./charts/supplier_ranking_bottom10";
import BuyerRankingBottom10 from "./charts/buyer_ranking_bottom10";
import AvgSuccessMarkupPercent from "./charts/all_avg_success_markup_percent";
import SupplierDisplayTop10 from "./charts/supplier_display_top10";
import BuyerDisplayTop10 from "./charts/buyer_display_top10";
import TotalProfitDaily from "./charts/all_total_profit_daily";
// import TotalVolumeDaily from "./charts/all_total_volume_daily";

import InitialLayout from "./Layout";

const originals = ["1", "2", "3", "4", "7", "8", "9", "11", "12", "13", "14", "15", "16", "17", "18"];

const def_orig = ["1", "2", "3", "4", "7", "8", "9", "11", "12", "13", "14"];

const componentList = {
  "1": AvgSuccessRate,
  "2": AvgMarkupPercent,
  "3": AvgMarkupPercentWeekly,
  "4": AvgMarkupPercentDaily,
  // "5": AvgSucessRateWeekly,
  // "6": AvgSucessRateDaily,
  "7": AvgDisplayCountWeekly,
  "8": AvgDisplayCountDaily,
  "9": HotelDisplayTop10,
  // "10": HotelSuccessRateTop10,
  "11": SupplierRankingTop10,
  "12": BuyerRankingTop10,
  "13": SupplierRankingBottom10,
  "14": BuyerRankingBottom10,
  "15": AvgSuccessMarkupPercent,
  "16": SupplierDisplayTop10,
  "17": BuyerDisplayTop10,
  "18": TotalProfitDaily,
  // "19": TotalVolumeDaily
};

function Content({ packageType, size: { width }  })  {

  const [originalItems, setOrig] = useState(originals);
  const [items, setItems] = useState(def_orig);
  const [openMode, setOpenMode] = useState(true);

  useEffect(() => {
    setOrig(originals);
  }, [packageType]);

  const [layouts, setLayouts] = useState(
    getFromLS("layouts") || InitialLayout
  );

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const onLayoutSave = () => {
    saveToLS("layouts", layouts);
  };

  const onRemoveItem = (itemId) => {
    setItems(items.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };

  const toogleOpenMode = () => {
    setOpenMode(!openMode);
  }

  return (
    <>
      <TopBar
        onLayoutSave={onLayoutSave}
        items={items}
        onRemoveItem={onRemoveItem}
        onAddItem={onAddItem}
        originalItems={originalItems}
        toogleOpenMode={toogleOpenMode}
        openMode={openMode}
      />
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
        width={width}
        isDraggable={!openMode}
        onLayoutChange={onLayoutChange}
      >
        {items.map((key) => (
          
            <div
              key={key}
              className="widget"
              data-grid={{ w: 3, h: 2, x: 0, y: Infinity }}>
                <Widget
                  id={key}
                  onRemoveItem={onRemoveItem}
                  userInteractType={!openMode}
                  component={componentList[key]}
                />
            </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(Content);

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {}
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}
