import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Text, View, ActivityIndicator } from 'react-native';

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

import Content from "./Content";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { handleLogout } from "./components/Logout";

import BuyerPage from "./pages/sidebarPages/BuyerPage";
import SupplierPage from "./pages/sidebarPages/SupplierPage";
import BuyerSupplier from "./pages/sidebarPages/BuyerSupplier";
import HomePage from "./pages/sidebarPages/HomePage";
import Profile from "./pages/Profile";

import { useTranslation } from "react-i18next";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import "./assets/css/styles.css";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth,
    overflow: 'hidden',
  },

  contentShift: {
    transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


function Theme() {

  const [open, setOpen] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [SelectedPage, setPage] = useState(BuyerPage("premium"));
  const [CustomPage, setCustomizable] = useState(true);

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);  

  let navigate = useNavigate();
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const uid = window.sessionStorage.getItem("uid");

  useEffect(() => {
    if(uid === null) {
      handleLogout();
      navigate("/login", { replace: true });
    }
  }, [uid]);

  const pageList = {
    0 : Profile("premium"),
    "buyerPred": BuyerPage("premium"),
    "supplierPred": SupplierPage("premium"),
    "offerPred": BuyerSupplier("premium"),
    "homeDash": HomePage("premium")
  }

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  var theme = createTheme({
    palette: {
      type: darkMode ? "dark" : "light"
    }
  });

  theme = responsiveFontSizes(theme);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleSidebar = (e) => {
    const selected_page = e.currentTarget.value;

    if(selected_page === "customDash") {
      setCustomizable(true);
    }
    else {
      setCustomizable(false);
      setPage(selected_page);
    }
  };

  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#d83534" />
        </View>
    );
  }
  if (error) {
      return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
          </View>
      );
  }
  else {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        <div className={classes.root}>
          <CssBaseline />
          <Header
            handleDrawerToggle={handleDrawerToggle}
            toggleDarkMode={toggleDarkMode}
            darkMode={darkMode}
            handleSidebar={handleSidebar} 
          />
          <Sidebar handleDrawerClose={handleDrawerClose} open={open} handleSidebar={handleSidebar} />
          <main className={clsx(classes.content, { [classes.contentShift]: open })}>
            <div className={classes.drawerHeader} />
            {CustomPage &&
              <Content packageType="premium"/>
            }
            {!CustomPage &&
              <>
                {pageList[SelectedPage]}
              </>
            }      
          </main>
        </div>
      </ThemeProvider>
    );
  }

}

export default Theme;
