import React, { useState } from 'react';

import { Text, View, ActivityIndicator } from 'react-native';

import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';

import { useTranslation } from "react-i18next";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

function Profile() {

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const email = window.sessionStorage.getItem("email");
    const fname = window.sessionStorage.getItem("fname");
    const lname = window.sessionStorage.getItem("lname");
    const address = window.sessionStorage.getItem("address");
    const company = window.sessionStorage.getItem("company");
    const vkn = window.sessionStorage.getItem("tax_no");
    const tax_address = window.sessionStorage.getItem("tax_address");

    const { t, i18n } = useTranslation();
    
    const updateProfile=(e) => { 

        e.preventDefault();
        setIsLoading(true);

        const user_lname2 = document.getElementById('user_lname').value;
        const user_fname2 = document.getElementById('user_fname').value;
        const user_address2 = document.getElementById('user_address').value;
        const user_company2 = document.getElementById('user_company').value;
        const user_vkn2 = document.getElementById('user_vkn').value;
        const user_tax_add2 = document.getElementById('user_tax_add').value;

        const update_list_obj = {
            "user_fname": user_fname2,
            "user_lname": user_lname2,
            "user_address": user_address2,
            "user_company": user_company2,
            "user_vkn": user_vkn2,
            "user_tax_add": user_tax_add2,
            "user_email": email
        }

        fetch(process.env.REACT_APP_REQ_IP + '/update_user', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(update_list_obj)}).then(res => res.json()).then(data => {

            if(data.result === true) {
                window.sessionStorage.setItem("fname", user_fname2);
                window.sessionStorage.setItem("lname", user_lname2);
                window.sessionStorage.setItem("address", user_address2);
                window.sessionStorage.setItem("company", user_company2);
                window.sessionStorage.setItem("tax_no", user_vkn2);
                window.sessionStorage.setItem("tax_address", user_tax_add2);

                setIsLoading(false);

                enqueueSnackbar(t(data.message), { 
                    variant: 'success',
                    persist: false
                });

            }
            else {

                setIsLoading(false);
                
                enqueueSnackbar(t(data.message), { 
                    variant: 'error',
                    persist: false
                });
            }

        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    }
    

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#d83534" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className="container-fluid">
                <div className="row">
                    <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                    <div className="col-md-8 h-auto">
                        <Card className="profile-container h-100">
                            <CardHeader title="Profile"></CardHeader>
                            
                            <div className="card-body">
                                <form method="POST" onSubmit={updateProfile}>
                                    <div className="row">
                                         <div className="col-md-12 pr-1">
                                            <TextField className='w-100 mb-2' id="user_email" type="email" label={t("Email")}variant="outlined" value={email} disabled />
                                        </div> 
                                    </div>
    
                                    <div className="row">
                                        <div className="col-md-6 pr-1">
                                            <TextField className='w-100 my-2' id="user_fname" type="text" label={t("First Name")} variant="outlined" defaultValue={fname} required/>
                                        </div>
    
                                        <div className="col-md-6 pl-1">
                                            <TextField className='w-100 my-2' id="user_lname" type="text" label={t("Last Name")} variant="outlined" defaultValue={lname} required/>
                                        </div>
                                    </div>
    
                                    <div className="row">
                                        <div className="col-md-12">
                                            <TextField className='w-100 my-2' id="user_address" type="text" label={t("Address")} variant="outlined" defaultValue={address} required/>
                                        </div>
                                    </div>
    
                                    <div className="row">
                                        <div className="col-md-12">
                                            <TextField className='w-100 my-2' id="user_company" type="text" label={t("Company Name")} variant="outlined" defaultValue={company} required/>
                                        </div>
                                    </div>
    
                                    <div className="row">
                                        <div className="col-md-6 pr-1">
                                            <TextField className='w-100 my-2' id="user_vkn" type="text" label={t("Tax Number")} variant="outlined" defaultValue={vkn} />
                                        </div>
                                        <div className="col-md-6 pl-1">
                                            <TextField className='w-100 my-2' id="user_tax_add" type="text" label={t("Tax Address")} variant="outlined" defaultValue={tax_address} />
                                        </div>
                                    </div>
    
                                    <input type="submit" value={t("Save")} className="btn profile-save-btn d-flex ml-auto"/>                                  
    
                                    <div className="clearfix"></div>
                                </form>
                            </div>
    
                        </Card>
    
                    </div>
            
    
                    <div className="col-md-4">
                        <Card className="card-user">
                            <div className="card-image">
                                <img src="/side-1.png" alt="profile-background"/>
                            </div>
                            <div className="card-body">
                                <div className="author">
                                    <a href="#">
                                        <img className="avatar border-gray" src="/sd.png" alt="..."/>
                                        <h5 className="profile-title">{fname} {lname}</h5>
                                    </a>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
    
        );
    }


}

export default Profile;
