import React from "react";
import Card from "@material-ui/core/Card";

import { makeStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import AddList from "./AddList";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  }
}));

export default function TopBar({ onLayoutSave, items, onRemoveItem, onAddItem, originalItems, toogleOpenMode, openMode }) {

  const spec_msg = window.sessionStorage.getItem("spec_msg");
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <p className="m-auto text-danger">{spec_msg}</p>
      <AddList
        items={items}
        onRemoveItem={onRemoveItem}
        onAddItem={onAddItem}
        originalItems={originalItems}/>
      <IconButton color="inherit" aria-label="save" onClick={onLayoutSave}>
        <SaveIcon />
      </IconButton>

      <IconButton
          color="inherit"
          aria-label="lock"
          onClick={toogleOpenMode}
          edge="start">
          {openMode ? <LockIcon /> : <LockOpenIcon />}
        </IconButton>

    </Card>
  );
}
