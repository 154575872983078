import React, { useState, useEffect } from 'react';
import ReactEcharts from "echarts-for-react"; 

import * as echarts from 'echarts/core';
import { TooltipComponent, VisualMapComponent } from 'echarts/components';

import { Scatter3DChart } from 'echarts-gl/charts';
import { Grid3DComponent } from 'echarts-gl/components';

import { useTheme } from '@material-ui/core/styles';

import { Image } from 'react-native';
import { useTranslation } from "react-i18next";
import SeasonalityImg from "../assets/img/season.png";

export default function VolumeProfitDisplay3D({ hvalue="100%", buyer="", supplier="", start="", end="" }) {

  const [val, setVal] = useState(null);

  const [serverMsg, setServermsg] = useState(null);
  const { t, i18n } = useTranslation();

  const themeS = useTheme();
  const themeMode = themeS.palette.type;
  const themeBack = themeS.palette.background.paper;
  const themeText = themeS.palette.text.primary;

  const uid = window.sessionStorage.getItem("uid");

  echarts.use([
    TooltipComponent,
    VisualMapComponent,
    Grid3DComponent,
    Scatter3DChart,
  ]);


  useEffect(() => {

    var list_obj = {
        "buyer": buyer,
        "supplier": supplier,
        "start": start,
        "end": end,
    }

      fetch(process.env.REACT_APP_REQ_IP + '/d3_profit_count_display/<start>/<end>/<buyer>/<supplier>', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
        if(data.results) {

          const tmpData = JSON.parse(data.veri);

          const option = {
            grid3D: {
              axisLine: {
                show: true,
                lineStyle: {
                  color: themeText,
                  width: 0.5
                }
              },
              axisPointer: {
                lineStyle: {
                  color: themeText
                }
              },
              axisTick: {
                show: false
              },
              splitLine: {
                lineStyle: {
                  color: themeText,
                  opacity: 0.2 
                }
              },
              viewControl: {
                autoRotate: true,
              },
            },
            tooltip: {
              show:true,
              trigger: 'item',
              showContent: true,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              textStyle: {
                color: "black"
              },
              formatter: function (params) {
                return "Name : " + params.data.name + '<br/>' +
                "Booked Rate : " + params.data.success + '<br/>' +
                'Check Availability Count : ' + params.data.display + '<br/>' +
                'Total Profit : ' + params.data.profit + '<br/>'
              }
            },
            visualMap: [
              {
                left: 0,
                top: "20",
                calculable: true,
                dimension: 1,
                max: 10,
                inRange: {
                  color: [
                    '#f1f720',
                    '#28da38',
                    '#004a87'
                  ]
                },
                textStyle: {
                  color: themeText
                }
              },
            ],
            toolbox: {
              feature: {
                dataView: { show: true, readOnly: true },
                saveAsImage: { show: true }
              }
            },
            xAxis3D: {
              name: "Booked Rate",
              type: 'value',
              nameTextStyle: {
                color: themeText
              }
            },
            yAxis3D: {
              name: "Check Availability Count",
              type: 'value',
              nameTextStyle: {
                color: themeText
              }
            },
            zAxis3D: {
              name: "Total Profit",
              nameTextStyle: {
                color: themeText
              }
            },
            dataset: {
              dimensions: [
                'success',
                'display',
                'profit',
              ],
              source: tmpData
            },
            series: [
              {
                name: "3D Analysis",
                type: 'scatter3D',
                symbolSize: 16,
                itemStyle: {
                  borderWidth: 0
                },
              }
            ]
          };
    
          setVal(option);

        }
        else {
          setServermsg(data.message);
        }
  
      })
      .catch(err => {
          console.log(err);
      });


  }, [uid, buyer, supplier, start, end, themeBack]);


  return (
    <>
      {val === null && 
        <div className="blur-container"> 
            <Image source={SeasonalityImg}  style={{ height:300, borderRadius:"1em", borderColor:"#424243", borderWidth:"1px" }} resizeMethod='scale' />
            <span>Data is invalid</span>
            {serverMsg !== null &&
                <span>{t(serverMsg)}</span>
            }
        </div>
      }
      {val !== null && serverMsg === null &&
        <ReactEcharts option={val} />      
      }
    </>

  );
}



